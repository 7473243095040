import React, { useEffect, useState } from 'react';
import "chart.js/auto";
import { Doughnut, Line } from 'react-chartjs-2';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
    fetchAnalyticsData,
    irdSelector,
} from '../../store/slices/ird/irdSlice';
import * as htmlToImage from 'html-to-image';
import { Breadcrumb, Button } from 'antd';


  const options = {
    scales: {
        y: {
            ticks: {
                stepSize: 1,
                beginAtZero: true,
            },
        },
    },
};
function getNewDate() {
    let tDate = new Date().toLocaleDateString().split('/');
    let newDateToday = `${tDate[2]}-${tDate[1]}-${tDate[0]}`;
    return newDateToday;
}
const Reports = (props) => {
    const [mealType, setMealType] = useState('');
    const [totalCount, setTotalCount] = useState(10);
    const dispatch = useDispatch();
    let formatedDate = getNewDate();

    const { loading, analyticsData = [] } = useSelector(irdSelector);

    const filterStartDateRef = React.useRef();
    const filterEndDateRef = React.useRef();

    useEffect(() => {
        let filterStartDate = filterStartDateRef.current?.value;
        let filterEndDate = filterEndDateRef.current?.value;
        dispatch(
            fetchAnalyticsData(
                filterStartDate,
                filterEndDate,
                mealType,
                totalCount
            )
        );
    }, []);

    const applyFilters = () => {
        let filterStartDate = filterStartDateRef.current?.value;
        let filterEndDate = filterEndDateRef.current?.value;
        dispatch(
            fetchAnalyticsData(
                filterStartDate,
                filterEndDate,
                mealType,
                totalCount
            )
        );
    };

    const data = {
        labels: analyticsData.map((item) => item.item_name),
        datasets: [
            {
                label: 'items Choice',
                data: analyticsData.map((item) => +item.count),
                fill: true,
                backgroundColor: 'rgba(118, 179, 12, 0.3)',
                borderColor: 'rgba(118, 179, 12, 0.5)',
                pointBorderColor: '#76b30c',
                pointBackgroundColor: '#76b30c',
            },
        ],
    };
    const data2 = {
        labels: analyticsData.map((item) => item.item_name),
        datasets: [
            {
                label: 'items Choice',
                data: analyticsData.map((item) => +item.count),
                backgroundColor: [
                    '#2085ec',
                    '#72b4eb',
                    '#0a417a',
                    '#8464a0',
                    '#41c6b5',
                    '#3991ab',
                    '#fe4e75',
                    '#edfe6a',
                    '#cea9bc',
                    '#323232',
                    '#5f584b',
                ],
            },
        ],
    };

    const printDocument = () => {
        const input = document.getElementById('print-details');
        const downloadableImgtag = document.getElementById('downloadableImg');
        htmlToImage
            .toJpeg(input)
            .then(function (dataUrl) {
                var img = new Image();
                img.src = dataUrl;
                downloadableImgtag.setAttribute('href', dataUrl);
                downloadableImgtag.click();
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    };
    const ActionHeader = () => {
        return (
            <div className='action-header'>
                <div className='action-left'>
                    <div className='page-title'>IN ROOM DINING</div>
                    <Breadcrumb
                        items={[
                            {
                                title: <a href='/ird'>IRD</a>,
                            },
                            {
                                title: 'Analytics Report',
                            },
                        ]}
                    />
                </div>
                <div className='action-right'>
                    <button
                        className='btn btn-icon'
                        onClick={() => printDocument()}
                    >
                        <i className='fa fa-download'></i> {'   '}
                        Download report
                    </button>
                </div>
            </div>
        );
    };
    return (
        <>
            <ActionHeader />
            <section
                className='order-history-wrapper  report_wrapper'
                id='print-details'
            >
                <div className='aside-right' id='print-details'>
                    <a
                        id='downloadableImg'
                        download
                        style={{ display: 'none' }}
                        href=''
                        aria-label='download button'
                    ></a>

                    <div className='reports_main'>
                        <div className='home-order-screen'>
                            {loading ? (
                                'loading...'
                            ) : analyticsData.length == 0 ? (
                                'No Data found'
                            ) : (
                                <Line data={data} options={options} />
                            )}
                        </div>

                        <div className='home-order-screen'>
                            {loading
                                ? 'loading...'
                                : analyticsData.length == 0
                                ? 'No Data found'
                                : analyticsData?.length > 0 && (
                                      <table className='table table-striped table-hover table-history'>
                                          <thead>
                                              <tr>
                                                  <th className='text-center'>
                                                      ITEM NAME
                                                  </th>
                                                  <th className='text-center'>
                                                      COUNT (Item selected by
                                                      users)
                                                  </th>
                                                  <th className='text-center'>
                                                      REVENEUE
                                                  </th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              {analyticsData.map(
                                                  (item, index) => {
                                                      return (
                                                          <tr
                                                              key={item.item_id}
                                                          >
                                                              <td className='text-center '>
                                                                  {
                                                                      item.item_name
                                                                  }
                                                              </td>
                                                              <td className='text-center '>
                                                                  {item.count}
                                                              </td>

                                                              <td className='text-center'>
                                                                  {
                                                                      item?.total_revenue
                                                                  }
                                                              </td>
                                                          </tr>
                                                      );
                                                  }
                                              )}
                                          </tbody>
                                      </table>
                                  )}
                        </div>
                        <div className='home-order-screen'>
                            {loading ? (
                                'loading...'
                            ) : analyticsData.length == 0 ? (
                                'No Data found'
                            ) : (
                                <Doughnut data={data2} />
                            )}
                        </div>
                    </div>
                </div>

                <div className='aside-left'>
                    <div className='aside_main_wrapper'>
                        <div className='filter-type-option'>
                            <label>Meal type: </label>
                            <select
                                name='itemSearch'
                                className='form-control search__input'
                                id='itemSearch'
                                form='carform'
                                value={mealType}
                                onChange={(e) => setMealType(e.target.value)}
                                defaultValue={mealType}
                            >
                                <option value=''>All</option>
                                <option value='breakfast'>Breakfast</option>
                                <option value='lunch'>Lunch</option>
                                <option value='dinner'>Dinner</option>
                                <option value='miscellaneous'>
                                    Miscellaneous
                                </option>
                            </select>
                        </div>

                        <div className='filter-type-option'>
                            <label htmlFor='start_date'>Start Date:</label>
                            <input
                                type='date'
                                id='start_date'
                                name='start_date'
                                placeholder='Enter start Date'
                                ref={filterStartDateRef}
                                defaultValue={formatedDate}
                            />
                        </div>
                        <div className='filter-type-option'>
                            <label htmlFor='end_date'>End Date:</label>
                            <input
                                type='date'
                                id='end_date'
                                name='end_date'
                                placeholder='Enter end Date'
                                ref={filterEndDateRef}
                                defaultValue={formatedDate}
                            />
                        </div>
                        <div className='filter-type-option'>
                            <label>Top items count: </label>
                            <select
                                name='itemSearch'
                                className='form-control search__input'
                                form='carform'
                                value={totalCount}
                                onChange={(e) => setTotalCount(e.target.value)}
                                defaultValue={totalCount}
                            >
                                <option value='10'>10</option>
                                <option value='15'>15</option>
                                <option value='20'>20</option>
                                <option value='25'>25</option>
                                <option value='30'>30</option>
                            </select>
                        </div>
                        <Button type='primary' onClick={applyFilters}>
                            Apply
                        </Button>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Reports;
