import {
    LogoutOutlined,
    MailOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
} from '@ant-design/icons';
import { Menu, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import IIc from '../assets/icons/IRD.png';
import SIc from '../assets/icons/Spa.png';
import LIc from '../assets/icons/Laundry.png';
import CIc from '../assets/icons/Connect.png';
import BIc from '../assets/icons/Booking.png';
import FIc from '../assets/icons/Feed.png';
import axios from 'axios';
import AppConstant from '../constant/AppConstant';
import { createPortal } from 'react-dom';
import { getHeaderServices } from '../services/apiService';
import { useNotification } from '../contexts/NotificationContextProvider';
import { useSelector } from 'react-redux';
import { commonSelector } from '../store/slices/common/commonSlice';
import { removeUserDataFromBrowserStorage } from '../helper/_commonHelper';
function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const IIcon = () => <img src={IIc} alt='irdi' className='sidebar-menu-icon' />;
const SIcon = () => <img src={SIc} alt='irdi' className='sidebar-menu-icon' />;
const LIcon = () => <img src={LIc} alt='irdi' className='sidebar-menu-icon' />;
const CIcon = () => <img src={CIc} alt='irdi' className='sidebar-menu-icon' />;
const BIcon = () => <img src={BIc} alt='irdi' className='sidebar-menu-icon' />;
const FIcon = () => <img src={FIc} alt='irdi' className='sidebar-menu-icon' />;
const BellBadge = () => {
    return (
        <span className='dot-badge'>
            <i className='fa fa-bell'></i>
            <span className='badge-count'></span>
        </span>
    );
};
const rootSubmenuKeys = ['ird', 'spa', 'laundry', 'connect', 'booking', 'feed'];

const Sidebar = ({ collapsedClickHandler, collapsed }) => {
    const location = useLocation();
    let path = location.pathname || '';
    if (path.length > 0) {
        path = path.substring(1);
        if (path.length > 0) {
            path = path.split('/');
        }
    }
    let defaultOpenKey = [];
    if (path.length > 0) {
        let base = rootSubmenuKeys.includes(path[0]);
        if (base) {
            defaultOpenKey = [path[0]];
        }
    }

    const [openKeys, setOpenKeys] = useState(defaultOpenKey || []);
    const [availableServices, setAvailableServices] = useState({});
    const [serviceLoader, setSericeLoader] = useState(false);
    const [logoutLoading, setLogoutLoading] = useState(false);
    const { hotelDetails = {} } = useSelector(commonSelector);
    const is_menu_access =
        hotelDetails.is_menu_access_permission === 1 ||
        hotelDetails.is_menu_access_permission === null;
    const isMenuToggle =
        hotelDetails.is_menu_view_toggle_permission === 1 ||
        hotelDetails.is_menu_view_toggle_permission === null;
    const { notificationData = {} } = useNotification();
    const navigate = useNavigate();

    useEffect(() => {
        fetchAdminMenus();
    }, []);
    const items = [
        (availableServices['ird'] ||
            hotelDetails.type === 'hotel_admin' ||
            hotelDetails.type === 'ird_manager' ||
            hotelDetails.type === 'restaurant_manager') &&
            getItem(
                hotelDetails.type === 'restaurant_manager' ? 'Restaurant': `In Room Dining`,
                hotelDetails.type === 'restaurant_manager' ? 'restaurant': 'ird',
                <>
                    {(notificationData['ird'] ||
                        notificationData['restaurent']) && <BellBadge />}
                    <IIcon />
                </>,
                [
                    getItem('Dashboard', 'ird'),
                    isMenuToggle && getItem('Menu', 'ird/menu'),
                    is_menu_access &&
                        getItem('Menu Management', 'ird/edit-menu'),
                    getItem('Order History', 'ird/order-history'),
                    hotelDetails.type === 'hotel_admin' && getItem('Revenue', 'ird/revenue'),
                    hotelDetails.type === 'hotel_admin' && getItem('Analytics Report', 'ird/report'),
                ]
            ),
        (availableServices['spa'] || hotelDetails.type === 'spa_manager') &&
            getItem(
                'SPA ',
                'spa',
                <>
                    {notificationData['spa'] && <BellBadge />}
                    <SIcon />
                </>,
                [
                    getItem('Dashboard', 'spa'),
                    getItem('Menu', 'spa/menu'),
                    getItem('Order History', 'spa/order-history'),
                ]
            ),
        (availableServices['laundry'] ||
            hotelDetails.type === 'laundry_manager') &&
            getItem(
                'Laundry',
                'laundry',
                <>
                    {notificationData['laundry'] && <BellBadge />}
                    <LIcon />
                </>,
                [
                    getItem('Dashboard', 'laundry'),
                    getItem('Menu', 'laundry/menu'),
                    getItem('Order History', 'laundry/order-history'),
                ]
            ),
        (availableServices['ays_services'] ||
            hotelDetails.type === 'connect_manager') &&
            getItem(
                'Other Services',
                'connect',
                <>
                    {notificationData['connect'] && <BellBadge />}
                    <CIcon />
                </>,
                [
                    getItem('Dashboard', 'connect'),
                    getItem('Order History', 'connect/order-history'),
                ]
            ),
        (availableServices['booking_services'] ||
            hotelDetails.type === 'booking_manager') &&
            getItem(
                'Booking',
                'booking',

                <>
                    {notificationData['booking'] && <BellBadge />}
                    <BIcon />
                </>,
                [
                    getItem('Dashboard', 'booking'),
                    getItem('Cancel Orders', 'booking/Cancelled-Orders'),
                    getItem('Order History', 'booking/order-history'),
                ]
            ),
        (hotelDetails.type === 'feed_manager') &&
            getItem(
                'Feed',
                'feed',
                <>
                    {notificationData['ird'] && <BellBadge />}
                    <FIcon />{' '}
                </>,
                [getItem('Dashboard', 'feed')]
            ),
    ];
    const fetchAdminMenus = async () => {
        try {
            setSericeLoader(true);
            let response = await getHeaderServices();
            if (response.status === 200) {
                let data = response.data?.data || {};
                setAvailableServices(data);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setSericeLoader(false);
        }
    };
    const onClick = (e) => {
        navigate(`${e.key}`);
    };

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    const logout = () => {
        revokeToken();
        navigator.serviceWorker
            .getRegistrations()
            .then(function (registrations) {
                for (let registration of registrations) {
                    registration.unregister();
                }
            });
    };

    const revokeToken = async () => {
        try {
            setLogoutLoading(true);
            let response = await axios.get(
                AppConstant.config.appBaseUrl + `/logout`
            );
            if (response.status === 200) {
                removeUserDataFromBrowserStorage()
                navigate('/login', { replace: true });
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setLogoutLoading(false);
        }
    };

    return (
        <div className={'sidebar-wrapper ' + (collapsed ? 'collapsed' : '')}>
            <div className='hotel-name'> {hotelDetails?.name || ''}</div>
            {serviceLoader ? (
                <Spin />
            ) : (
                <Menu
                    onClick={onClick}
                    mode='inline'
                    openKeys={openKeys}
                    items={items}
                    onOpenChange={onOpenChange}
                    theme={'dark'}
                    inlineCollapsed={collapsed}
                />
            )}
            <div className='logout-wrapper'>
                <div className='logout-container' onClick={logout}>
                    <LogoutOutlined />{' '}
                    <span className='logout-text'>Log out</span>
                </div>
                <div
                    className='toggle-button'
                    onClick={() => collapsedClickHandler()}
                >
                    {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </div>
            </div>
            {logoutLoading
                ? createPortal(
                      <div className='overlay-loader'>
                          <p>Loading...</p>
                      </div>,
                      document.getElementById('root')
                  )
                : ''}
        </div>
    );
};
export default Sidebar;
