import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './stylesheets/main.scss';
import './App.css';
import { getToken } from './init-fcm';
import { useDispatch } from 'react-redux';
import { updateDeviceToken } from './store/slices/ird/irdSlice';
import RequireAuth from './Auth/RequireAuth';
import AuthCallback from './Auth/AuthCallback';
import Login from './Auth/Login';
import MenuEdit from './pages/ird/MenuEdit';

import Layout from './pages/Layout';

//ird
import IRD from './pages/ird';
import Revenue from './pages/ird/Revenue';
import Reports from './pages/ird/Reports';
import IRDOrderHistory from './pages/ird/OrderHistory';
import SPAOrderHistory from './pages/spa/OrderHistory';
import DashboardIRD from './pages/ird/Dashboard';
import MenuIRD from './pages/ird/Menu';

//spa
import DashboardSPA from './pages/spa/Dashboard';
import SPA from './pages/spa';
import MenuSPA from './pages/spa/Menu';

//laundry
import Laundry from './pages/laundry';
import DashboardLaundry from './pages/laundry/Dashboard';
import MenuLaundry from './pages/laundry/Menu';
import LaundryOrderHistory from './pages/laundry/OrderHistory';


//connect
import Connect from './pages/connect';
import DashboardConnect from './pages/connect/Dashboard';
import MenuConnect from './pages/connect/Menu';
import ConnectOrderHistory from './pages/connect/OrderHistory';

//booking
import DashboardBooking from './pages/booking/Dashboard';
import BookingOrderHistory from './pages/booking/OrderHistory';
import Booking from './pages/booking';
import CancelOrder from './pages/booking/CancelOrder';

import DashboardFeed from './pages/feed/Dashboard';
import Feed from './pages/feed';

const App = () => {
    const [isTokenFound, setIsTokenFound] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        async function tokenFunc() {
            let data = await getToken(setIsTokenFound);
            if (data) {
                console.log({ isTokenFound, data });
                dispatch(updateDeviceToken(data));
            }
        }
        tokenFunc();
    }, [setIsTokenFound]);

    const NotFound = () => {
        return <p>Not found</p>;
    };
    return (
        <Routes>
            <Route
                path='/'
                element={
                    <RequireAuth>
                        <Layout />
                    </RequireAuth>
                }
            >
                <Route index element={<DashboardIRD />}></Route>
                <Route path='ird' element={<IRD />}>
                    <Route index element={<DashboardIRD />} />
                    <Route path='menu' element={<MenuIRD />} />
                    <Route path='revenue' element={<Revenue />} />
                    <Route path='report' element={<Reports />} />
                    <Route path='order-history' element={<IRDOrderHistory />} />
                    <Route path='edit-menu' element={<MenuEdit />} />
                </Route>
                <Route path='spa' element={<SPA />}>
                    <Route index element={<DashboardSPA />} />
                    <Route path='menu' element={<MenuSPA />} />
                    <Route path='order-history' element={<SPAOrderHistory />} />
                </Route>

                <Route path='laundry' element={<Laundry />}>
                    <Route index element={<DashboardLaundry />} />
                    <Route path='menu' element={<MenuLaundry />} />
                    <Route path='order-history' element={<LaundryOrderHistory />} />
                </Route>
                <Route path='connect' element={<Connect />}>
                    <Route index element={<DashboardConnect />} />
                    <Route path='menu' element={<MenuConnect />} />
                    <Route path='order-history' element={<ConnectOrderHistory />} />
                </Route>

                <Route path='booking' element={<Booking />}>
                    <Route index element={<DashboardBooking />} />
                    <Route path='Cancelled-Orders' element={<CancelOrder />} />
                    <Route path='order-history' element={<BookingOrderHistory />} />
                </Route>
                <Route path='feed' element={<Feed />}>
                    <Route index element={<DashboardFeed />} />
                </Route>
            </Route>

            <Route exact path='/login' element={<Login />} />
            <Route path='/auth/callback' exact element={<AuthCallback />} />
            <Route path='*' exact={true} element={<NotFound />} />
        </Routes>
    );
};

export default App;
